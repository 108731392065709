import React from 'react'

const WhiteLogo = () => {
   return( 
   <React.Fragment>
       <img src={require('../images/logo_white.svg')} alt="logo" className="img-fluid" style={{maxWidth:"180px"}}/>
   </React.Fragment>
  ) 
}

export default WhiteLogo