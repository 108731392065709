import { Link } from "gatsby"
import React, {useState, useEffect } from "react"
import Logo from '../components/Logo'
import MobileMenu from '../components/MobileMenu'
import {Button} from 'react-bootstrap'
import Menus from "./Menus"
import { getCurrentUser, logout} from "../utils/auth"
import LoginRegisterButton from "./LoginRegisterButton"
import {navigate} from 'gatsby'

const Header = () => {

  /*Mobile Menu*/
  const [mobile, setMobile] = useState({
    showMenu : false
  })

  const enableMobileMenu = () => {
    setMobile((prevState) => {
        return { ...prevState, showMenu : !prevState.showMenu};
       });
  }
  const hideMobileMenu = () => {
    setMobile((prevState) => {
        return { ...prevState, showMenu : false};
       });
  }

  const [header, setHeader] = useState({
    activeClass : ""
  });

  useEffect(() => {
    window.addEventListener('scroll', () => {
      let activeClass = '';
      if(window.scrollY > 50){
          activeClass = 'fixed';
      }
      setHeader((prevState) => {
       return { ...prevState, activeClass : activeClass};
      });
   });
  },[])


  const currentUser = getCurrentUser();
  const loginId = Object.keys(currentUser).length > 0 ? currentUser.userData.loginId : ''
  const studentName = Object.keys(currentUser).length > 0 ? currentUser.userData.studentName : ''

  return(
      <React.Fragment>
         <MobileMenu open={mobile.showMenu} close={hideMobileMenu}/>
         <header className={`header ${header.activeClass}`}>
          <nav className="navbar navbar-expand-lg fixed-top white-bg">
             <div className="container">
                <Link className="navbar-brand" to ="/">
                  <Logo />
                </Link>
                <Button onClick={() => enableMobileMenu()} className="mobileToggle">
                  <i className="ti-menu"></i>
                </Button>
                <ul className="navbar-nav ml-auto menu">
                  <Menus />
                  {
                    loginId ? <li><a href="# " className="dropdown-toggle">{`Hi, ${studentName ? studentName.replace(/ .*/,'') : 'Student' }`}</a>
                    <ul className="sub-menu">
                        <li>
                          <Link to="/"
                          onClick=
                          {event => {
                          event.preventDefault()
                          logout(() => navigate(`/`))
                          }}
                          className="dropdown-item">Logout</Link>
                        </li>
                    </ul>
                  </li>
                  :
                  <LoginRegisterButton />  
                  }
                  
                </ul>
             </div>   
          </nav>
        </header>
      </React.Fragment>
  )
}


export default Header
