import React, {useState} from 'react'
import {Form, Button, Alert} from 'react-bootstrap'
import { handleLogin } from '../../utils/auth'
import {baseURL, kananBaseURL} from '../../config'
import {navigate} from 'gatsby'

const emailRegex = RegExp(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/);

export default function RegisterModal(props) {

    const [show, setShow] = useState({
        signUp: false,
        login: true,
        isOtp: false
    })

    const handleSignUp = (type) => {
        setShow((prevState) => {
        return { ...prevState, login: (type === 'login')? true : false, signUp: (type === 'register')? true : false, isOtp: false };
        });
    }

    const formValid = ({formErrors, ...rest}) => {
        let valid = true;
        Object.values(formErrors).forEach(val => {
          val.length > 0 && (valid = false);
        })
        Object.values(rest).forEach(val => {
          val === null && (valid = false)
        })
        return valid;
    } 


    /*Login Form*/
    const [loginForm, setLoginForm] = useState(
        {
          email: null,
          password: null,
          success: false,
          isFormValid: true,
          errorMessage: "",
          redirect:'/',
          formErrors: {
             email: "",
             password: ""
          }
        }
    )
    const handleLoginChange = e => {
        const {name, value} = e.target;
        let formErrors = loginForm.formErrors
        switch(name)
        {     
           case "email":
             formErrors.email = emailRegex.test(value)  ? "" : "Invalid Email Address";
             break;
     
           case "password":
             formErrors.password = value.length < 3  ? "minimum 3 characters required" : "";
             break;
     
           default:
             break;
        }
        setLoginForm((prevState) => {
           return { ...prevState, formErrors, [name]: value, isFormValid: true};
        });
    }
    const handleLoginSubmit = (e) => {
        e.preventDefault();
        if(formValid(loginForm))
        {
            setLoginForm((prevState) => {
            return { ...prevState, isFormValid: true };
            });

            handleLogin(loginForm).then((status) => {
               if(status){
                    if(props.isTestLaunch){
                        LaunchTest()  
                    }
                    else{
                        navigate('/');
                        document.getElementById('student-form').reset();
                        props.close()
                    }
                   
               }
            }).catch((e) => {
            setLoginForm((prevState) => {
                return { ...prevState, errorMessage: e.message};
                });
            })

            setTimeout(
                () => setLoginForm((prevState) => {
                    return { ...prevState, errorMessage: ""};
                   }),
                3000
            )
          
        }
        else{
            setLoginForm((prevState) => {
            return { ...prevState, formErrors: {email: loginForm.email !== null ? loginForm.formErrors.email : "error", password: loginForm.password !== null ? loginForm.formErrors.password : "error"}};
           });
          console.error('Login Form Is Invalid')
        }
     }
    /*Login Form*/


    /*Register*/
    const [TestForm, setTestForm] = useState(
        {
          studentname: null,
          email: null,
          mobile: null,
          failure: false,
          success: false,
          isFormValid: true,
          errorMessage: "",
          formErrors: {
             studentname: "",
             email: "",
             mobile: ""
          }
        }
      )
    
    const [testOptions, setTestOptions] = useState(
        {
          studentName: "",
          emailId: "",
          mobileNumber: "",
          otp: "",
          otpRefId: ""
        }
      )
    
    const [otp, setOtp] = useState(
        {
          otpNumber: "",
          otpError : null
        }
     )

     const setUser = user => (window.localStorage.tryMockUser = JSON.stringify(user))

     const handleRegisterChange = e => {
        const {name, value} = e.target;
        let formErrors = TestForm.formErrors
        switch(name)
        {
           case "studentname":
             formErrors.studentname = value.length < 3  ? "minimum 3 characters required" : "";
             break;
     
           case "email":
             formErrors.email = emailRegex.test(value)  ? "" : "Invalid Email Address";
             break;
     
           case "mobile":
             formErrors.mobile = value.length < 10  ? "Invalid Mobile Number" : "";
             break;
     
           default:
             break;
        }
        setTestForm((prevState) => {
           return { ...prevState, formErrors, [name]: value, isFormValid: true};
          });
     }
     const handleRegisterSubmit = (e) => {
        e.preventDefault();
        if(formValid(TestForm))
        {
          setTestForm((prevState) => {
            return { ...prevState, isFormValid: true };
            });
  
          const mock_options = {
            "studentName": TestForm.studentname,
            "emailId": TestForm.email,
            "mobile": TestForm.mobile,
            "companyCode": "kananprep",
            "accountId": "trymocktest"
          }  
  
          sendOTP(mock_options)
          
        }
        else{
          setTestForm((prevState) => {
            return { ...prevState, formErrors: {studentname: TestForm.studentname === null ? "error" : TestForm.formErrors.studentname, email: TestForm.email !== null ? TestForm.formErrors.email : "error", mobile: TestForm.mobile !== null ? TestForm.formErrors.mobile : "error"}};
           });
          console.error('Mock Test Form Is Invalid')
        }
     }  
     const sendOTP = (options) => {

        setTestOptions ((prevState) => {
          return { ...prevState, studentName: options.studentName, emailId: options.emailId, mobileNumber: options.mobile}
        });
    
        const emailVerifyData = Object.keys(options).reduce((prevVal, currKey) => {
            prevVal[currKey] = options[currKey]
          return prevVal
        },{})
    
        let query = Object.keys(emailVerifyData)
           .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(emailVerifyData[k]))
           .join('&');
    
        fetch(`${baseURL}/mocktest-register?${query}`, {
          method: 'get'
          }).then(function(response) {
            return response.json();
            }).then(function(res) {
              if(res.success) {
    
                if(res.data.token) {
                  setUser(res.data);
                  if(props.isTestLaunch){
                    LaunchTest()
                  }
                }
                else{
                   setShow((prevState) => {
                    return { ...prevState, signUp: false, login: false, isOtp: true };
                   });
                   setTestOptions((prevState) => {
                     return { ...prevState, otpRefId: res.data.otpRequestId}
                   });
                }
                
              } else {
                setTestForm((prevState) => {
                  return { ...prevState, errorMessage: res.message, submitState: true }
                })
              }
    
          }).catch((err) => {
              console.error(err);
          });
          
      }
    
      const handleOtpChange = e => {
        const { value } = e.target;
        
        setTestOptions((prevState) => {
          return { ...prevState, otp: value }
        });
        
        setOtp((prevState) => {
          return { ...prevState, otpNumber: value, otpError: null };
         });
       }
    
      const handleVerifyRegister = () => {
        if(otp.otpNumber.length < 6) {
          setOtp((prevState) => {
            return { ...prevState, otpError: "Invalid OTP" };
           });
    
        } else {
    
          const test_options = {
            "companyCode" : "kananprep",
            "studentName": testOptions.studentName,
            "emailId": testOptions.emailId,
            "mobileNumber": testOptions.mobileNumber,
            "accountId" : "trymocktest",
            "otp" : parseInt(testOptions.otp),
            "otpRefId" : testOptions.otpRefId
          } 
    
          fetch(`${baseURL}/mocktest-register`, {
            method: 'post',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(test_options)
          }).then(function(response) {
            return response.json();
          }).then(function(res) {
            if(res.success) {
              
                
              setTestForm((prevState) => {
                return { ...prevState, success: true };
              });
              setUser(res.data);
              if(props.isTestLaunch){
                LaunchTest()  
              }
              else{
                navigate('/');
                props.close()
              }
    
            } else {
              setOtp((prevState) => {
                return { ...prevState, otpError: "Something went wrong !, please try again later" }
              })
            }
          });
    
        }
      }
    /*Register*/


    /*Test Launch*/
    const LaunchTest = () => {

        const currentUser = localStorage.getItem('tryMockUser');
        const userData = JSON.parse(currentUser)
        const token = userData ? userData.token : null
    
        const launch_options = {
          "testId" : props.testId,
          "courseId" : props.courseId,
          "rurl" : window.location.origin,
          "groupId" : "0",
        } 
       
        IELTS_Test_Launch(launch_options, token)
        
      }
    
      const IELTS_Test_Launch = (options, token) => {
        fetch(`${kananBaseURL}/kanan/generate-token`, {
          method: 'post',
          headers: {
            "Content-Type": "application/json",
            "Authorization" : token
          },
          body: JSON.stringify(options)
        }).then(function(response) {
          return response.json();
        }).then(function(res) {
    
          if(res.success) {
    
            setTestForm((prevState) => {
              return { ...prevState, success: true };
            });
    
            props.close()
            
            navigate(res.data.redirectUrl)
    
          } else {
            setShow((prevState) => {
                return { ...prevState, signUp: true, login: false, isOtp: true };
            });
            setTestForm((prevState) => {
              return { ...prevState, failure : true, success: false}
            })
          }
    
        });
      }
    /*Test Launch*/


    return (
    <React.Fragment>
        <div className={`loginModalOuter ${props.open ? "d-block" : "d-none"}`}>
            <span class="loginModalOverlay"></span>
            <div class="loginModalPopup">
            <span role="button" tabIndex="0" aria-label="overlay" className="modalClose ti-close" onClick={props.close}></span>
            {show.login && 
            <>
                <h3>Log In Now</h3>
                <p>Please login to start using our stuff</p>
                <Form id="student-form" method="post" className="formOuter" onSubmit={handleLoginSubmit}>
                    {loginForm.errorMessage && <Alert variant="danger" style={{ fontSize: "14px", textAlign: "center" }}> {loginForm.errorMessage} </Alert>}
                    <Form.Group controlId="email" className={loginForm.formErrors.email.length > 0 ? 'error': null}>
                    <Form.Control type="email" onChange={handleLoginChange} name="email" placeholder="Email"/>
                    <span className="form-icons ti-email"></span>
                    </Form.Group>
                    <Form.Group controlId="password" className={loginForm.formErrors.password.length > 0 ? 'error': null}>
                    <Form.Control type="password" onChange={handleLoginChange} name="password" placeholder="Password"/>
                    <span className="form-icons ti-lock"></span>
                    </Form.Group>
                    <Button type="submit" id="register-submit">
                     Sign In
                    </Button>
                </Form>
                <h6>Don't have an account? <span onClick={() => handleSignUp('register')}>Sign Up</span></h6>
            </>
            }
            {show.signUp &&
            <>
            <h3>Sign Up Now</h3>
            <p>Please fills the details and create account</p>
            <Form id="student-form" method="post" className="formOuter" onSubmit={handleRegisterSubmit}>
                {TestForm.success && <Alert variant="success" style={{fontSize: '14px', textAlign: 'center'}}>Successfully registered !</Alert>}
                {TestForm.failure && <Alert variant="danger" style={{fontSize: '14px', textAlign: 'center'}}>Oops, something went wrong!</Alert>}
                <Form.Group controlId="studentname" className={TestForm.formErrors.studentname.length > 0 ? 'error': null}>
                <Form.Control type="studentname" onChange={handleRegisterChange} name="studentname" placeholder="Name"/>
                <span className="form-icons ti-user"></span>
                </Form.Group>
                <Form.Group controlId="email" className={TestForm.formErrors.email.length > 0 ? 'error': null}>
                <Form.Control type="email" onChange={handleRegisterChange} name="email" placeholder="Email"/>
                <span className="form-icons ti-email"></span>
                </Form.Group>
                <Form.Group controlId="mobile" className={TestForm.formErrors.mobile.length > 0 ? 'error': null}>
                <Form.Control type="number" min="0" onChange={handleRegisterChange} name="mobile" placeholder="Mobile Number"/>
                <span className="form-icons ti-mobile"></span>
                </Form.Group>
                <Button type="submit" id="register-submit">
                  Submit
                </Button>
            </Form>
            <h6>Already have an account ? <span onClick={() => handleSignUp('login')}>Log In</span></h6>
            </>
            }
            {
              show.isOtp &&
              <div className="otpScreen getStartOuter loginPopOuter">
                {otp.otpError && <Alert variant="danger" style={{ fontSize: "14px", textAlign: "center" }}> {otp.otpError}</Alert>}
                  <h3>OTP Verification</h3>
                  <p>Please enter the OTP sent to your email address</p>
                  <div className="otp-wrapper mt-5">
                    <input onChange={handleOtpChange} name="otpnumber" type="text" maxlength="6" />
                  </div>
                  <Button className="mt-5 btn btn-primary" onClick={handleVerifyRegister}>Verify</Button> 
              </div>
            }
            </div>
        </div>
    </React.Fragment>
    )
}
