import { Link } from "gatsby"
import React from "react"

const Menus = () => {
  return(
      <React.Fragment>
        <li><Link to ="/" activeClassName="active">Home</Link></li>          
        <li><Link to ="/ielts-academic/listening/" activeClassName="active">IELTS Academic </Link></li>         
        <li><Link to ="/ielts-academic/listening/">IELTS General</Link></li>          
        <li><Link to ="/ielts-academic/listening/">Duolingo</Link></li>         
        <li><Link to ="/ielts-academic/listening/">SAT</Link></li>          
        <li><Link to ="/ielts-academic/listening/">GRE</Link></li>          
        <li><Link to ="/ielts-academic/listening/">PTE</Link></li>          
        <li><Link to ="/ielts-academic/listening/">TOFEL</Link></li>          
        <li><Link to ="/contact/" activeClassName="active">Contact</Link></li>
      </React.Fragment>
  )
}


export default Menus
