import { navigate } from "gatsby"
import {baseURL} from '../config'

const isBrowser = typeof window !== `undefined`

const getUser = () =>
  window.localStorage.tryMockUser
    ? JSON.parse(window.localStorage.tryMockUser)
    : {}

const setUser = user => (window.localStorage.tryMockUser = JSON.stringify(user))

export const handleLogin = async ({ email, password, redirect }) => {
  if (!isBrowser) return false

    const opts = {
      loginId: email,
      password: password,
      "companyCode": "kananprep",
      "accountId": "trymocktest"
    }

    return fetch(`${baseURL}/login`, {
    method: 'post',
    headers: {
        "Content-Type": "application/json",
    },
    body: JSON.stringify(opts)
    }).then(function(response) {
    return response.json();
    }).then(function(res) {
        if(res.success) {
          setUser(res.data);
          return true
        } else {
          throw new Error(res.message) 
        }
    });
}

export const isLoggedIn = () => {
  if (!isBrowser) return false
  const user = getUser();
  return !!user.token
}

export const getCurrentUser = () => isBrowser && getUser()

export const logout = callback => {
  if (!isBrowser) return

  setUser({})
  callback()
}