import React, {useState} from 'react'
import {Link} from 'gatsby'
import RegisterModal from './Modal/RegisterModal'

export default function LoginRegisterButton() {

    const [loginModal, setLoginModal] = useState({
        show: false
    })

    const handleLogin = () => {
        setLoginModal((prevState) => {
            return { ...prevState, show: true }
        });
    }

    const closeLoginModal = () => {
        setLoginModal((prevState) => {
            return { ...prevState, show: false }
        });
    }

    return (
        <React.Fragment>
            <li onClick={handleLogin}><Link to="#">Login</Link></li>
            {loginModal.show && <RegisterModal open={loginModal.show} close={closeLoginModal} />}
        </React.Fragment>
    )
}
