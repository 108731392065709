import React from 'react'

const Logo = () => {
   return( 
   <React.Fragment>
       <img src={require('../images/logo.svg')} alt="logo" className="img-fluid" style={{maxWidth:"180px"}}/>
   </React.Fragment>
  ) 
}

export default Logo