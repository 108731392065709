import React from 'react'
import '../styles/mobile-menu.css'
import Menus from './Menus'

const MobileMenu = (props) => {

  let attachedClasses = ['mobnavbar', 'close'];
  let overlayClasses = ['mobnavoverlay', 'close'];


  if(props.open)
  {
      attachedClasses = ['mobnavbar', 'open'];
      overlayClasses = ['mobnavoverlay', 'open'];
  }

  return(
    <div>
      <div className={attachedClasses.join(' ')}>
        <ul className="navbar-nav mobnav">
          <Menus /> 
        </ul>
      </div>
      <div role="button" tabIndex="0" aria-label="overlay" className={overlayClasses.join(' ')} onClick={props.close} onKeyDown={props.close}></div>
    </div>
  )
}

export default MobileMenu
